import React, { Component } from 'react'
import { Admin, Resource as ResourceOriginal } from 'react-admin'

// Styles for masterListWithdraws
import './table-styles.css'

import JssProvider from 'react-jss/lib/JssProvider'
import { generateClassName } from './generateClassName'

// import UI components (main admin)
import UsersList from './components/admin/users/UsersList'
import UserCreate from './components/admin/users/UserCreate'

import cashiersReducer from './components/admin/cashiers/cashiersReducer'
import cashiersSaga from './components/admin/cashiers/cashiersSaga'

import WithdrawsList from './components/admin/withdraws/WithdrawsList'
import withdrawsReducer from './components/admin/withdraws/withdrawsReducer'
import withdrawsSaga from './components/admin/withdraws/withdrawsSaga'

import usersReducer from './components/admin/users/usersReducer'
import usersSaga from './components/admin/users/usersSaga'

import Login from './components/login/Login'
import Layout from './Layout'

// import APIs
import authClient from './api/authClient.js'
import restApi from './api/restApi.js'

import { version as APP_VERSION } from '../package.json'

import { Dashboard as DashboardIcon, LocalAtm, People, ShowChart } from '@material-ui/icons'
import Dashboard1 from './components/admin/dashboard/Dashboard1'
import Dashboard2_Bitmex from './components/admin/dashboard/Dashboard2_Bitmex'
import IconBitmex from './components/other/IconBitmex.jsx'
import IconFinanse from './components/other/IconFinanse.jsx'
import IconT from './components/other/IconT.jsx'
import IconExchange from './components/other/IconExchange.jsx'
import Dashboard3_Finanse from './components/admin/dashboard/Dashboard3_Finanse'

import { createBrowserHistory } from 'history'
import { wsConnect } from './api/wsApi'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ScreenSharing from './components/other/ScreenSharing'
import Dashboard4_Transfery from './components/admin/dashboard/Dashboard4_Transfery'
import Dashboard5_Rates from './components/admin/dashboard/Dashboard5_Rates'
import Dashboard6_Charts from './components/admin/dashboard/Dashboard6_Charts'
import IconVM from './components/other/IconVM'
import Dashboard7_VirtualMoney from './components/admin/dashboard/Dashboard7_VirtualMoney'
import Dashboard8_BinanceLoans from './components/admin/dashboard/Dashboard8_BinanceLoans'
import IconBinance from './components/other/IconBinance'

// Significant Hack !
// For some reason we have a task - keep url in browser clear from search params (url part after "?")
// This hack solve this problem but broke navigation
// What we do:
// After every change of real history - push changes to fake history
export const history = createBrowserHistory()
export const historyFake = createBrowserHistory()
history.listen(location => {
  historyFake.push({
    pathname: location.pathname,
    search: ''
  })
})

class App extends Component {
  state = {
    dataProvider: restApi
  }

  componentDidMount () {
    // If authorised - connect WS
    if (sessionStorage.getItem('loginToken')) {
      wsConnect()
    }
  }

  render () {
    const { dataProvider } = this.state

    if (!dataProvider) {
      return (
        <div className='loader-container'>
          <div className='loader'> Loading...</div>
          {' '}
        </div>
      )
    }

    const resources = [
      <Resource
        name='masterListFranchisee'
        list={UsersList}
        create={UserCreate}
        icon={People}
      />,
      <Resource
        name='masterListWithdraws'
        list={WithdrawsList}
        icon={LocalAtm}
      />,
      <Resource
        name='dashboard'
        list={Dashboard1}
        icon={DashboardIcon}
      />,
      <Resource
        name='bitmex'
        list={Dashboard2_Bitmex}
        icon={IconBitmex}
      />,
      <Resource
        name='finanse'
        list={Dashboard3_Finanse}
        icon={IconFinanse}
      />,
      <Resource
        name='transfers'
        list={Dashboard4_Transfery}
        icon={IconT}
      />,
      <Resource
        name='rates'
        list={Dashboard5_Rates}
        icon={IconExchange}
      />,
      <Resource
        name='charts'
        list={Dashboard6_Charts}
        icon={ShowChart}
      />,
      <Resource
        name="virtualMoney"
        list={Dashboard7_VirtualMoney}
        icon={IconVM}
      />,
      <Resource
        name="binanceLoans"
        list={Dashboard8_BinanceLoans}
        icon={IconBinance}
      />
    ]

    return (
      <div className='app'>
        <JssProvider generateClassName={generateClassName}>
          <Admin
            title='Admin panel'
            history={history}
            dataProvider={dataProvider}
            authProvider={authClient}
            loginPage={Login}
            appLayout={Layout}
            customReducers={{
              cashiersState: cashiersReducer,
              usersState: usersReducer,
              withdrawsState: withdrawsReducer
            }}
            customSagas={[usersSaga, cashiersSaga, withdrawsSaga]}
            locale='en'
          >
            {permissions => resources.filter(res =>
              permissions === 'ALL' || permissions.includes(res.props.name)
            )}
          </Admin>
        </JssProvider>

        <div id='ws-off-caption'>WS connect OFF</div>
        <div className='app-version'>v{APP_VERSION}</div>

        <ScreenSharing />
      </div>
    )
  }
}

export default App

// ===================== Hack to get dispatch from react-admin ==========================
//
// We need it for async actions (initiated through WebSocket from server)
// If wanna no-hack solution - use redux-saga:
// https://medium.com/@pierremaoui/using-websockets-with-redux-sagas-a2bf26467cab

function mapDispatchToProps (dispatch) {
  window.raDispatch = dispatch
  return {}
}

const Resource = compose(
  connect(null, mapDispatchToProps)
)(ResourceOriginal)
