import React from 'react'
import BaseModule from '../BaseModule'

export default class ModuleTransfers extends BaseModule {

  renderBody (data) {
    const rows = data.map((item, i) => {
      return (
        <tr key={i} style={{ color: item.color }}>
          <td><span dangerouslySetInnerHTML={{ __html: item.source }}/></td>
          <td><span dangerouslySetInnerHTML={{ __html: item.target }}/></td>
          <td>
            <span dangerouslySetInnerHTML={{ __html: item.amount }}/>&nbsp;
            <span dangerouslySetInnerHTML={{ __html: item.currency }}/>
          </td>
          <td><span dangerouslySetInnerHTML={{ __html: item.state }}/></td>
          <td><span dangerouslySetInnerHTML={{ __html: item.sendingTime }}/></td>
          <td><span dangerouslySetInnerHTML={{ __html: item.receivedTime }}/></td>
        </tr>
      )
    })

    return (
      <table className="table table-stripped">
        <tbody>{rows}</tbody>
      </table>
    )
  }

}
