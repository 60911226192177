import BaseDashboard from './BaseDashboard'
import ModuleSimpleTable from './modules/ModuleSimpleTable'
import ModuleActions from './modules/ModuleActions'
import ModuleBalances from './modules/ModuleBalances'
import ModuleTransfers from './modules/ModuleTransfers'
import ModuleLastTx from './modules/ModuleLastTx'
import ModuleLogged from './modules/ModuleLogged'

// routes
export const GET_ACTIONS = 'GET_ACTIONS'
export const UPDATE_ACTIONS = 'UPDATE_ACTIONS'
export const GET_CRYPTO_STATUS = 'GET_CRYPTO_STATUS'
export const GET_BALANCES = 'GET_BALANCES'
export const GET_TRANSFERS = 'GET_TRANSFERS'
export const GET_HANGING_TX = 'GET_HANGING_TX'
export const POST_HANGING_TX = 'POST_HANGING_TX'
export const GET_LAST_TX = 'GET_LAST_TX'
export const PUSH_LAST_TX = 'PUSH_LAST_TX'
export const RECALC_LAST_TX = 'RECALC_LAST_TX'
export const GET_LOGGED = 'GET_LOGGED'
export const INIT_STREAM = 'INIT_STREAM'
export const STOP_STREAM = 'STOP_STREAM'
export const GET_PRICES_COMPARISON = 'GET_PRICES_COMPARISON'

export default class Dashboard1 extends BaseDashboard {

  title = 'Dashboard'

  modules = [
    { id: 'reset' },
    { id: 'actions', component: ModuleActions, route: GET_ACTIONS },
    { id: 'cryptoStatus', component: ModuleSimpleTable, route: GET_CRYPTO_STATUS },
    { id: 'balances', component: ModuleBalances, route: GET_BALANCES },
    { id: 'transfers', component: ModuleTransfers, route: GET_TRANSFERS },
    { id: 'lastTx', component: ModuleLastTx, route: GET_LAST_TX },
    // { id: 'pricesComparison', component: ModulePricesComparison, route: GET_PRICES_COMPARISON },
    { id: 'logged', component: ModuleLogged, route: GET_LOGGED },
  ]

  moduleStylesDefault = {
    reset: 'left: 0; top: 0; width: 32px; height: 32px',
    actions: 'left: 10px; top: 40px;  width: 170px; height: 220px',
    cryptoStatus: 'left: 10px; top: 270px;  width: 170px; height: 200px',
    balances: 'left: 810px; top: 10px; width: 450px; height: 250px',
    transfers: 'left: 190px; top: 10px; width: 610px; height: 250px',
    lastTx: 'left: 10px; top: 480px; width: 950px; height: 350px',
    // pricesComparison: 'left: 500px; top: 270px; width: 300px; height: 200px',
    logged: 'left: 810px; top: 270px; width: 650px; height: 200px',
  }

}
