/*eslint-disable */
import { stringify } from 'query-string'
import { CREATE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE } from 'react-admin'
import config from '../config'
import { Mutex } from 'async-mutex'
import {
  RESET_FRANCHISEE_PASSWORD,
  UPDATE_USER,
  USER_ACTION,
  USER_SWITCH_2FA,
} from '../components/admin/users/usersActions'
import {
  ADD_CASHIER,
  ADD_TRANSACTION,
  CASHIER_ACTION,
  CASHIER_SWITCH_2FA,
  GET_CASHIER,
  GET_CASHIERS_LIST,
  GET_TRANSACTIONS_LIST,
  RESET_CASHIER_PASSWORD,
  UPDATE_CASHIER,
} from '../components/admin/cashiers/cashiersActions'
import { SUBMIT_WITHDRAWS, WITHDRAW_TABLE_ACTION } from '../components/admin/withdraws/withdrawsActions'

import {
  GET_ACTIONS,
  GET_BALANCES,
  GET_CRYPTO_STATUS,
  GET_HANGING_TX,
  GET_LAST_TX,
  GET_LOGGED,
  GET_PRICES_COMPARISON,
  GET_TRANSFERS,
  INIT_STREAM,
  POST_HANGING_TX,
  PUSH_LAST_TX,
  RECALC_LAST_TX,
  STOP_STREAM,
  UPDATE_ACTIONS,
} from '../components/admin/dashboard/Dashboard1'
import { GET_BITMEX_HISTORY, GET_BITMEX_POSITION } from '../components/admin/dashboard/Dashboard2_Bitmex'
import { GET_PROFIT_AGREGATED } from '../components/admin/dashboard/Dashboard3_Finanse'
import { showErrorToastr } from '../toastr'
import {
  GET_DISTORTION_SETUP,
  GET_MANUAL_ORDERS,
  GET_SET_OF_TRANSFERS,
  GET_TRADE_INTERFACE,
  GET_TRANSFER_OPTIONS,
  POST_DISTORTION_SETUP,
  POST_MANUAL_ORDER,
  POST_TRADE_INTERFACE,
  POST_TRANSFER,
} from '../components/admin/dashboard/Dashboard4_Transfery'
import { GET_RATES } from '../components/admin/dashboard/Dashboard5_Rates'
import { GET_CHART_LIVE } from '../components/admin/dashboard/Dashboard6_Charts'
import { VIRTUAL_MONEY } from '../components/admin/dashboard/Dashboard7_VirtualMoney'
import {
  CANCEL_BINANCE_ACTION,
  GET_BINANCE_ACTIONS,
  GET_BINANCE_ACTIONS_VALUES,
  GET_LOANS_INFO,
  POST_BINANCE_FORM,
} from '../components/admin/dashboard/Dashboard8_BinanceLoans'

const mutex = new Mutex()

const fetch = require('./fetch')

const apiUrl = config.apiUrl

function clearEmptyParams (data) {
  for (let k in data) {
    if (data.hasOwnProperty(k) && data[k] === '') {
      delete data[k]
    }
  }
  return data
}

const convertRESTRequestToHTTP = (type, resource, params) => {
  let url = ''
  let data = {}
  let query = {}
  const options = {
    method: 'POST',
    headers: new Headers({
      'Auth-Token': sessionStorage.getItem('token'),
    }),
  }

  switch (type) {
    case GET_LIST: {
      if (resource === 'masterListFranchisee') {
        const { filter, pagination, sort } = params
        const { page, perPage } = pagination

        if (filter.hasOwnProperty('active')) {
          filter.active = filter.active ? 1 : 0
        }
        query = {
          ...filter,
          page: (page - 1).toString(),
          size: perPage.toString(),
        }
        if (sort.field && sort.order) {
          query.sortField = sort.field
          query.sortOrder = sort.order
        }
      }

      url = `${apiUrl}/${resource}.php`
      break
    }

    case GET_CASHIERS_LIST:
    case GET_TRANSACTIONS_LIST:
      url = `${apiUrl}/${resource}.php`
      const { franchiseeId, pagination, sortParams } = params
      query = {
        id: franchiseeId,
        page: pagination.page,
        size: pagination.size,
      }
      if (sortParams) {
        query.sortField = sortParams.orderBy
        query.sortOrder = sortParams.order
      }
      break

    case GET_CASHIER:
      url = `${apiUrl}/${resource}.php`
      query = { id: params.id }
      break

    case GET_MANY: {
      url = `${apiUrl}/${resource}.php`
      break
    }

    case CREATE:
    case ADD_CASHIER:
      // For resources: masterFranchisee, masterCashier
      url = `${apiUrl}/${resource}.php?add`
      data = clearEmptyParams(params.data)
      break

    case USER_ACTION:
    case CASHIER_ACTION:
    case WITHDRAW_TABLE_ACTION:
      url = `${apiUrl}/${resource}.php`
      query = params
      break

    case SUBMIT_WITHDRAWS: {
      url = `${apiUrl}/${resource}.php?withdraws`
      data = params.data
      break
    }
    case UPDATE_USER: {
      url = `${apiUrl}/${resource}.php`
      query = { edit: params.id }
      data = clearEmptyParams(params.data)
      break
    }
    case UPDATE_CASHIER: {
      url = `${apiUrl}/${resource}.php`
      query = { edit: params.id }
      data = clearEmptyParams(params.data)
      break
    }
    case ADD_TRANSACTION: {
      url = `${apiUrl}/${resource}.php`
      const data = clearEmptyParams(params.data)
      query = { id: params.id, ...data }
      break
    }

    case RESET_FRANCHISEE_PASSWORD:
    case RESET_CASHIER_PASSWORD:
      url = `${apiUrl}/${resource}.php`
      query = { resetPasswd: params.id }
      break

    case USER_SWITCH_2FA:
    case CASHIER_SWITCH_2FA:
      url = `${apiUrl}/${resource}.php`
      query = {
        id: params.id,
        secFA: params.value ? 'enable' : 'disable',
      }
      break

    // ===================== Dashboard1 ========================

    case GET_ACTIONS:
      url = `${apiUrl}/quarkActions.php`
      break

    case UPDATE_ACTIONS:
      url = `${apiUrl}/quarkActions.php`
      query = params
      break

    case GET_CRYPTO_STATUS:
      url = `${apiUrl}/quarkBTCState.php`
      break

    case GET_BALANCES:
      url = `${apiUrl}/quarkBalances.php?humanreadable=1`
      break

    case GET_TRANSFERS:
      url = `${apiUrl}/quarkTransfers.php`
      break

    case GET_HANGING_TX:
      url = `${apiUrl}/quarkHangingTx.php`
      break

    case POST_HANGING_TX:
      url = `${apiUrl}/quarkHangingTx.php`
      query = params
      break

    case GET_LAST_TX:
      url = `${apiUrl}/quarkLastTxs.php`
      break

    case PUSH_LAST_TX:
    case RECALC_LAST_TX:
      url = `${apiUrl}/quarkLastTxs.php`
      data = params
      break

    case GET_LOGGED:
      url = `${apiUrl}/quarkLogged.php`
      break

    case INIT_STREAM:
      url = `${apiUrl}/quarkLogged.php`
      query = { initStream: params.cashierId }
      break

    case STOP_STREAM:
      url = `${apiUrl}/quarkLogged.php`
      query = { stopStream: params.streamId }
      break

    case GET_PRICES_COMPARISON:
      url = `${apiUrl}/quarkPricesComparison.php`
      break

    // ===================== Dashboard2 ========================

    case GET_BITMEX_POSITION:
      url = `${apiUrl}/quarkBitmex.php?position`
      break

    case GET_BITMEX_HISTORY:
      url = `${apiUrl}/quarkBitmex.php?history`
      break

    // ===================== Dashboard3 ========================

    case GET_PROFIT_AGREGATED:
      url = `${apiUrl}/quarkProfitAggregated.php`
      break

    // ===================== Dashboard4 ========================

    case GET_TRANSFER_OPTIONS:
      url = `${apiUrl}/quarkOrderTransfer.php`
      break

    case POST_TRANSFER:
      url = `${apiUrl}/quarkOrderTransfer.php`
      query = params
      break

    case GET_MANUAL_ORDERS:
      url = `${apiUrl}/quarkManualOrders.php`
      break

    case POST_MANUAL_ORDER:
      url = `${apiUrl}/quarkManualOrders.php`
      query = params
      break

    case GET_SET_OF_TRANSFERS:
      url = `${apiUrl}/quarkSetOfTransfers.php`
      break

    case GET_DISTORTION_SETUP:
      url = `${apiUrl}/quarkDistortionSetup.php`
      break

    case POST_DISTORTION_SETUP:
      url = `${apiUrl}/quarkDistortionSetup.php`
      data = params
      break

    case GET_TRADE_INTERFACE:
      url = `${apiUrl}/quarkTradeInterface.php`
      break

    case POST_TRADE_INTERFACE:
      if (params.query.setOrder) {
        url = `${apiUrl}/quarkTradeInterface.php?setOrder`
      } else {
        url = `${apiUrl}/quarkTradeInterface.php`
        query = params.query
      }
      if (params.data) {
        data = params.data
      }
      break

    // ===================== Dashboard5 ========================

    case GET_RATES:
      url = `${apiUrl}/quarkRates.php`
      query = params
      break

    // ===================== Dashboard6 ========================

    case GET_CHART_LIVE:
      url = `${apiUrl}/quarkChartLive.php`
      query = params
      break

    // ===================== Dashboard7 ========================

    case VIRTUAL_MONEY:
      url = `${apiUrl}/masterListVirtualMoney.php`
      query = params
      break

    // ===================== Dashboard8 ========================

    case GET_LOANS_INFO:
      options.method = 'GET'
      url = `${apiUrl}/binance-loans/v2/info`
      break

    case GET_BINANCE_ACTIONS:
      options.method = 'GET'
      url = `${apiUrl}/binance-loans/v2/actions`
      break

    case CANCEL_BINANCE_ACTION:
      url = `${apiUrl}/binance-loans/v2/action/${params.id}/cancel`
      break

    case POST_BINANCE_FORM:
      const { action, ...form } = params
      url = `${apiUrl}/binance-loans/v2/actions`
      data = form
      break

    case GET_BINANCE_ACTIONS_VALUES:
      options.method = 'GET'
      url = `${apiUrl}/binance-loans/v2/actions/values`
      break

    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }

  const isQueryEmpty = Object.keys(query).length === 0
  const isDataEmpty = Object.keys(data).length === 0

  url = isQueryEmpty ? url : `${url}?${stringify(query)}`

  // Variants of data post - use one
  if (options.method === 'POST' && !isDataEmpty) {
    // options.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // const searchParams = new URLSearchParams();
    // Object.entries(data).forEach(([key, value]) => {
    //   searchParams.set(key, value)
    // })
    // options.body = searchParams

    // Hack - only for two routes use sending method: "multipart/form-data"
    if (type === PUSH_LAST_TX || type === RECALC_LAST_TX) {
      options.headers['Content-Type'] = 'multipart/form-data'
      const formData = new FormData()
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value)
      })
      options.body = formData
    } else {
      options.headers['Content-Type'] = 'application/json'
      options.body = JSON.stringify(data)
    }
  }

  return { url, options }
}

const convertHTTPResponseToREST = ({ type, headers, json, text }) => {
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      const dataKey = json.hasOwnProperty('withdraws') ? 'withdraws' : 'franchisees'

      // kludge, saving some data, to hide some columns
      if (dataKey === 'franchisees' && json['removeCols']) {
        window.usersListRemoveCols = json['removeCols']
      }

      return {
        type: dataKey,
        data: json[dataKey],
        total: json.totalItems || json[dataKey].length, // for franchisees
        state: json.state, // for withdraws
      }
    }

    case CREATE:
      return {
        data: json.franchisee ? json.franchisee : null,
      }

    case GET_ONE:
      const currentResponse = json.franchisees && json.franchisees[0]
      return { data: currentResponse }

    case WITHDRAW_TABLE_ACTION:
      return { data: null, state: json.state }

    case SUBMIT_WITHDRAWS:
      if (text) {
        return { data: json, text: text }
      } else {
        return { data: json }
      }

    // ===================== Dashboard1 ========================

    case GET_PRICES_COMPARISON:
      if (json) {
        json.data = {
          data: json.data,
          ranges: json.ranges,
        }
      }
      return { data: json }

    case GET_LAST_TX:
    case PUSH_LAST_TX:
    case RECALC_LAST_TX:
      if (json) {
        json.data = {
          data: json.data,
          additionalData: json.additionalData,
        }
      }
      return { data: json }

    // ===================== Dashboard3 ========================

    case GET_PROFIT_AGREGATED:
      if (json) {
        const { data, dataSeparated } = json
        json.data = { data, dataSeparated }
      }
      return { data: json }

    // ===================== Dashboard4 ========================

    case GET_TRANSFER_OPTIONS:
      if (json) {
        json.data = json.formData
      }
      return { data: json }

    case GET_MANUAL_ORDERS:
    case POST_MANUAL_ORDER:
      if (json) {
        const { data, formData } = json
        json.data = { data, formData }
      }
      return { data: json }

    case GET_DISTORTION_SETUP:
    case POST_DISTORTION_SETUP:
      if (json) {
        const { data, formData } = json
        json.data = { data, formData }
      }
      return { data: json }

    case GET_SET_OF_TRANSFERS:
      // If CORS - can't get some headers
      // https://developers.google.com/web/updates/2015/03/introduction-to-fetch#response_types
      const contentDisposition = headers.get('Content-Disposition')

      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1]
        : 'transfers.txt'

      return { data: { filename, text } }

    // ===================== Dashboard8 ========================

    case GET_LOANS_INFO:
      if (json) {
        const { data, translations } = json
        json.data = { data, translations }
      }
      return { data: json }

    // ===================== Default ========================

    default: {
      return { data: json }
    }

  }
}

export default async (type, resource, params) => {
  if (!sessionStorage.getItem('token')) {
    // Cancel unnecessary requests
    // Can be generated when not authenticated  user for some reason get to Resource route
    return
  }

  // for every request we need new token - so not allow async requests
  // const mutexRelease = await mutex.acquire()

  // show spinner
  const spinner = document.getElementById('loading-screen')
  spinner.style.display = 'block'

  const { url, options } = convertRESTRequestToHTTP(type, resource, params)
  const response = await fetch.fetchJson(url, options)

  // If we got new token - saving it
  const newToken = response.headers.get('Auth-Token')
  if (newToken) {
    sessionStorage.setItem('token', newToken)
  }

  // hide spinner
  spinner.style.display = 'none'

  // mutexRelease()

  const { json, headers, status } = response

  if (status < 200 || status >= 300) {
    // On Error
    if (status === 401 || status === 403) {
      // If not authorised - remove current token and reload page
      sessionStorage.removeItem('token')
      window.location.reload()
    } else {
      showErrorToastr(`Error ${status}`, json['msg'] || null)
      return Promise.reject({ data: null })
    }
  } else {
    // On Success
    const isText = headers.get('Content-Type').includes('text/plain')
    const text = isText ? response.body : null
    if (isText || json) {
      return convertHTTPResponseToREST({ type, headers, json, text })
    } else {
      // maybe server errors in response
      showErrorToastr(`Can't parse response data`, 'server errors maybe')
      return Promise.reject({ data: null })
    }
  }
}
