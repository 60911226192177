import React, { Component } from 'react'
import restApi from '../../../api/restApi'

const PARSE_ERROR_RELOAD_INTERVAL = 20
export const DEFAULT_RELOAD_INTERVAL = 30

export default class BaseModule extends Component {

  id
  route

  state = {
    moduleData: null,
    isDataError: false
  }

  fetchTimeoutId = null

  dataResolver = data => data

  constructor ({ id, route }) {
    super()
    this.id = id
    this.route = route
  }

  componentDidMount () {
    if (this.route) {
      this.fetchData(this.route)
        .then(data => this.onDataLoad(data))
    }
  }

  reloadData () {
    if (!this.route) {
      throw new Error(`No route for this module`)
    }
    clearTimeout(this.fetchTimeoutId)
    this.fetchData(this.route)
      .then(data => this.onDataLoad(data))
  }

  destroy () {}

  componentWillUnmount () {
    if (this.fetchTimeoutId) {
      clearTimeout(this.fetchTimeoutId)
    }
    this.destroy()
  }

  onDataLoad (d, once) {
    const data = d
      ? this.dataResolver(d)
      : null

    // reload data after interval
    if (!once) {
      const interval = data
        ? (data.interval ? data.interval : DEFAULT_RELOAD_INTERVAL)
        : PARSE_ERROR_RELOAD_INTERVAL

      if (this.route) {
        this.fetchDataWithTimeout(this.route, interval)
          .then(data => this.onDataLoad(data))
      }
    }

    this.setState({
      moduleData: data,
      isDataError: data === null
    })
  }

  // Fetch data
  // Return data or null in any case
  fetchData (route, postData = {}) {
    return new Promise(resolve => {
      restApi(route, '', postData)
        .then(({ data }) => resolve(data))
        .catch(() => resolve(null))
    })
  }

  fetchDataWithTimeout (route, timeoutSec) {
    return new Promise(resolve => {
      this.fetchTimeoutId = setTimeout(() => {
        this.fetchData(route).then(resolve)
      }, timeoutSec * 1000)
    })
  }

  render () {
    const { moduleData, isDataError } = this.state
    let title = moduleData && moduleData.name ? moduleData.name : this.id

    if (isDataError) {
      title += ' [DATA PARSE ERROR]'
    }

    let body = null
    try {
      const data = moduleData ? moduleData.data : null
      body = !this.route || data ? this.renderBody(data) : null
    } catch (e) {
      console.error(`Module "${this.id}" render error (maybe wrong server data) : `, e)
      title += ' [WRONG DATA]'
    }

    return (
      <div className={'module'}>
        <div className="module-header">{title}</div>
        <div className="module-body">{body}</div>
      </div>
    )
  }

  renderBody (data){
    /* override it in child component */
    return JSON.stringify(data)
  }
}
